<template>
	<section class="lp" id="js_pagetop">
		<!-- 背景 -->
		<div class="lp__inner">
			<h1 class="lp__header"><img src="~@/assets/img/lp_typhoon2022/title.png" alt="それって台風頭痛かも!? 台風シーズンの体調管理は頭痛ーると一緒に presented by 頭痛ーる"></h1>

			<!-- イントロ -->
			<div class="lp__intro">
				<div class="lp__intro--text01">
					<i class="sprite_typhoon balloon_01" />
					<p>台風ってイヤだよニャ~<br>怖いし&#8230;お出けできないし&#8230;</p>
				</div>
				<div class="lp__intro--text02">
					<i class="sprite_typhoon balloon_02" />
					<p>それに、台風シーズンは<br><em>「台風頭痛」</em>にも<br>注意が必要なんじゃ。</p>
				</div>
				<div class="lp__intro--text03">
					<i class="sprite_typhoon balloon_03" />
					<p>ん?&emsp;<em>台風頭痛</em>って、<br>なにかニャ?</p>
				</div>
			</div>
			<!-- /イントロ -->

			<!-- 目次 -->
			<nav class="lp__link">
				<div class="lp__link__inner">
					<h2 class="lp__link--title"><i class="sprite_typhoon linktitle_bg" /><span>いつも頭痛ーるのご利用<br>ありがとうございます!</span></h2>
					<p class="lp__link--lead">今回は、頭痛ーるを活用して<em>台風頭痛</em>を予防し、台風シーズンを元気に乗り切るコツをご紹介します!</p>
					<ul class="lp__link--list">
						<li><a href="javascript:void(0);" @click="onScroll('js_about')"><i class="sprite_typhoon typhoon_icon" /><span>台風頭痛ってなんだろう？</span></a></li>
						<li><a href="javascript:void(0);" @click="onScroll('js_usage')"><i class="sprite_typhoon typhoon_icon" /><span>台風シーズンの頭痛ーる活用法</span></a></li>
						<li><a href="javascript:void(0);" @click="onScroll('js_challenge')"><i class="sprite_typhoon typhoon_icon" /><span><small>台風頭痛を予防！</small>３日間チャレンジ</span></a></li>
					</ul>
					<div class="lp__link--text01">
						<i class="sprite_typhoon balloon_04" />
						<p>３日間チャレンジで台風<br>シーズンに使えるスタンプをゲットじゃ！</p>
					</div>
				</div>
			</nav>
			<!-- /目次 -->
		</div>
		<!-- /背景 -->

		<!-- 「台風頭痛」ってなんだろう？ -->
		<section class="lp-contents" id="js_about">
			<div class="lp-contents__inner">
				<h2 class="lp-contents__title"><span>台風が近づくと痛くなる</span>「台風頭痛」ってなんだろう？</h2>
				<div class="lp-contents__about">
					<div class="lp-contents__about--img"><i class=" sprite_typhoon about_img" /></div>
					<p class="lp-contents__about--text">台風が近づくとなんだか頭が痛い&#8230;<br>そんな台風頭痛の要因は?<br>そして、予防策は?<br>医師がわかりやすく解説します。</p>
					<a href="javascript:void(0);" @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=columnDetail`, _detailId: 95 } })" class="lp-contents__button gtm-link_click_count01" data-gtm-event-cat-label="台風特集ページ2022リンク1" data-gtm-event-act-label="trackingLpTyphoon2022Btn01">記事を読む</a>
				</div>
			</div>
		</section>
		<!-- /「台風頭痛」ってなんだろう？ -->

		<!-- 頭痛ーる活用法 -->
		<section class="lp-contents" id="js_usage">
			<div class="lp-contents__inner">
				<h2 class="lp-contents__title"><span>台風シーズンの</span>頭痛ーる活用法</h2>
				<section class="lp-contents__usage">
					<h3 class="lp-contents__usage--title"><i class="lp-contents__usage--title--top sprite_typhoon contentssubtitle_top" />台風シーズンは気圧グラフを<br>こまめにチェックしよう！<i class="lp-contents__usage--title--bottom sprite_typhoon contentssubtitle_bottom" /></h3>
					<div class="usage__slider">
						<div class="usage__slider--container swiper-container js_swiper">
							<ul class="swiper-wrapper sprite-usage">
								<li class="swiper-slide">
									<p>台風は気象現象の中で<br>最も急激な気圧の低下が起こる<br>現象です。</p>
									<i class="sprite_typhoon carousel_01" />
								</li>
								<li class="swiper-slide">
									<p>台風通過時は急激な気圧低下から<br>急激な気圧上昇に変わり、<br>気圧のV字変化が起こります。</p>
									<i class="sprite_typhoon carousel_02" />
								</li>
								<li class="swiper-slide">
									<p>頭痛ーるの気圧グラフは<br>6日先まで見られるので、<br>「薬を準備する」「予定を<br>調整する」といった事前の対策を<br>とることができますよ。</p>
									<i class="sprite_typhoon carousel_03" />
								</li>
								<li class="swiper-slide">
									<p>台風の進路や速度は変わることが多いので、台風頭痛の予防のためには、最新の予報をチェックすることが大切。こまめに気圧グラフを見るようにしてくださいね。</p>
									<i class="sprite_typhoon carousel_04" />
								</li>
								<li class="swiper-slide">
									<p>また、台風が接近・直撃しない<br>地域でも急激な気圧低下が<br>起こる場合があります。<br>遠くの台風でも、油断せず、<br>台風頭痛に備えましょう！</p>
									<i class="sprite_typhoon carousel_05" />
								</li>
							</ul>
						</div>
						<div class="usage__slider--nav">
							<div class="swiper-button-prev js_swiper_prev"></div>
							<div class="swiper-button-next js_swiper_next"></div>
						</div>
						<div class="swiper-pagination-bullets js_swiper_pagination"></div>
					</div>
					<div class="lp-contents__usage--text01">
						<i class="sprite_typhoon balloon_05" />
						<p>体調や飲んだ薬の記録もできるので便利だよ～。さっそく、今日の体調を記録してみるニャ！</p>
					</div>
					<a v-if="isAndroid" href="dcmdam://launch?url=http%3A%2F%2Fapp-manager.docomo.ne.jp%2FSpApps%2FdetailApp%3Fdcmstore_view%3Dnone%26cId%3D10000023012" class="lp-contents__button gtm-link_click_count01" data-gtm-event-cat-label="台風特集ページ2022リンク2Android" data-gtm-event-act-label="trackingLpTyphoon2022Btn02_android">今すぐダウンロード</a>
					<a v-else href="javascript:void(0);" @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=appDownload`, _backPage: 'top' } })" class="lp-contents__button gtm-link_click_count01" data-gtm-event-cat-label="台風特集ページ2022リンク2iOS" data-gtm-event-act-label="trackingLpTyphoon2022Btn02_ios">今すぐダウンロード</a>
					<div v-if="isAndroid" class="lp-contents__usage--text02">
						<i class="sprite_typhoon balloon_06" />
						<p>台風情報はプッシュ通知でもお知らせするぞ。一緒に台風頭痛を予防するのじゃ!</p>
					</div>
					<h3 class="lp-contents__usage--title" id="js_health_weather"><i class="lp-contents__usage--title--top sprite_typhoon contentssubtitle_top" />日々変化する健康天気指数も<br>毎日チェックしよう!<i class="lp-contents__usage--title--bottom sprite_typhoon contentssubtitle_bottom" /></h3>
					<div class="lp-contents__usage--healthweather"><i class="lp-contents__usage--healthweather--img sprite_typhoon healthweather" /></div>
					<p class="lp-contents__usage--healthweather--text">季節ごとの健康に関わる指数を毎日お知らせする「健康天気指数」。<br>健康管理士によるワンポイントアドバイスもあるので、台風シーズンの体調管理にお役立てくださいね。</p>
					<a href="javascript:void(0);" @click="$router.push({ name: 'Cpsite', query: { id: sugotokuContentsId }, hash: '#js_healthweather' })" class="lp-contents__button gtm-link_click_count01" data-gtm-event-cat-label="台風特集ページ2022リンク3" data-gtm-event-act-label="trackingLpTyphoon2022Btn03">今日の健康天気指数を見てみる</a>
				</section>
			</div>
		</section>
		<!-- /頭痛ーる活用法 -->

		<!-- ３日間チャレンジ -->
		<section class="lp-contents" id="js_challenge">
			<div class="lp-contents__inner">
				<h2 class="lp-contents__title"><span>台風頭痛を予防しよう！</span>３日間チャレンジ</h2>
				<div class="lp-contents__challenge">
					<p class="lp-contents__challenge--lead">ご自身の傾向を知るためのセルフチェックや手軽にできるストレッチなどの台風頭痛対策をご紹介します。</p>
					<div class="lp-contents__challenge--text01">
						<i class="sprite_typhoon balloon_07" />
						<p>３日間チャレンジをクリアするとスタンプをプレゼントするよー!</p>
					</div>
					<div class="lp-contents__challenge--stamptitle">▼【スゴ得限定】スタンプ ▼</div>
					<ul class="lp-contents__challenge--stamp">
						<li><img src="~@/assets/img/stamp/typhoon202208_stamp01.png" alt="タスケテー"></li>
						<li><img src="~@/assets/img/stamp/typhoon202208_stamp03.png" alt="台風襲来"></li>
						<li><img src="~@/assets/img/stamp/typhoon202208_stamp02.png" alt="大荒れの予感"></li>
						<li><img src="~@/assets/img/stamp/typhoon202208_stamp04.png" alt="いまいく"></li>
					</ul>
					<div class="lp-contents__challenge--box">
						<ul class="lp-contents__challenge--explanation">
							<li>・イラストをタップし、ポップアップ画面の「チャレンジする」をタップしてください。</li>
							<li>・1日にできるチャレンジは１つです。</li>
						</ul>
						<div class="lp-contents__challenge--term">掲載期間：2022年10月31日まで</div>
						<div class="lp-contents__challenge--inner">
							<div class="lp-contents__challenge--start"><i class="sprite_challenge challenge_start" /></div>
							<ol class="lp-contents__challenge--contents">
								<li>
									<span class="lp-contents__challenge--date"><i class="sprite_challenge challenge_ribbon_01" /></span>
									<span :class="['lp-contents__challenge--img', { active: challengeDisplayType[0].is_active }]"><i :class="['sprite_challenge', 'challenge01', challengeDisplayType[0].sprite_class]" /></span>
									<a v-if="challengeDisplayType[0].is_active" href="javascript:void(0);" @click="onClick(1, $event);" class="lp-contents__challenge--link gtm-link_click_count01" data-gtm-event-cat-label="台風特集ページ2022リンク4" data-gtm-event-act-label="trackingLpTyphoon2022Btn04">チャレンジする！</a>
								</li>
								<li>
									<span class="lp-contents__challenge--date"><i class="sprite_challenge challenge_ribbon_02" /></span>
									<span :class="['lp-contents__challenge--img', { active: challengeDisplayType[1].is_active }]"><i :class="['sprite_challenge', 'challenge02', challengeDisplayType[1].sprite_class]" /></span>
									<a v-if="challengeDisplayType[1].is_active" href="javascript:void(0);" @click="onClick(2, $event);" class="lp-contents__challenge--link gtm-link_click_count01" data-gtm-event-cat-label="台風特集ページ2022リンク5" data-gtm-event-act-label="trackingLpTyphoon2022Btn05">チャレンジする！</a>
									<span class="lp-contents__challenge--hiroshi"><i class="sprite_challenge challenge_hiroshi" /></span>
								</li>
								<li>
									<span class="lp-contents__challenge--date"><i class="sprite_challenge challenge_ribbon_03" /></span>
									<span :class="['lp-contents__challenge--img', { active: challengeDisplayType[2].is_active }]"><i :class="['sprite_challenge', 'challenge03', challengeDisplayType[2].sprite_class]" /></span>
									<a v-if="challengeDisplayType[2].is_active && isAndroid" href="javascript:void(0);" @click="onClick(3, $event);" class="lp-contents__challenge--link gtm-link_click_count01" data-gtm-event-cat-label="台風特集ページ2022リンク6Android" data-gtm-event-act-label="trackingLpTyphoon2022Btn06_android">チャレンジする！</a>

									<a v-else-if="challengeDisplayType[2].is_active && !isAndroid" href="javascript:void(0);" @click="onClick(3, $event);" class="lp-contents__challenge--link gtm-link_click_count01" data-gtm-event-cat-label="台風特集ページ2022リンク6iOS" data-gtm-event-act-label="trackingLpTyphoon2022Btn06_ios">チャレンジする！</a>
									<span class="lp-contents__challenge--maro"><i class="sprite_challenge challenge_maro" /></span>
								</li>
							</ol>
							<a href="javascript:void(0);" @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=stamp`, _stampType: 'typhoon', _backPage: 'top' } })" class="lp-contents__challenge--btn" :class="{ 'gtm-link_click_count01 active': isComplete }" data-gtm-event-cat-label="台風特集ページ2022リンク7" data-gtm-event-act-label="trackingLpTyphoon2022Btn07">プレゼントをもらう</a>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!-- /３日間チャレンジ -->

		<!-- アウトロ -->
		<div class="lp__outro">
			<p>with頭痛ーるで<br>台風頭痛に負けない秋に！</p>
			<div class="lp__outro--img"><i class="sprite_typhoon outro_img" /></div>
			<a href="javascript:void(0);" @click="onScroll('js_pagetop')" class="lp__outro--button-pagetop">上部にもどる</a>
			<a href="javascript:void(0);" @click="$router.push({ name: 'Cpsite', query: { id: sugotokuContentsId } })" class="lp__outro--button-back">TOPにもどる</a>
		</div>
		<!-- /アウトロ -->

		<!-- 下に吸着するアプリダウンロードボタン -->
		<aside class="sticky-bottom-appdownload">
			<a v-if="isAndroid" href="dcmdam://launch?url=http%3A%2F%2Fapp-manager.docomo.ne.jp%2FSpApps%2FdetailApp%3Fdcmstore_view%3Dnone%26cId%3D10000023012" class="sticky-bottom-appdownload--button gtm-link_click_count01" data-gtm-event-cat-label="台風特集ページ2022リンク8Android" data-gtm-event-act-label="trackingLpTyphoon2022Btn08_android"><img src="~@/assets/img/sugotoku_app_info_btn03.png" alt="今すぐダウンロード！"></a>
			<a v-else href="javascript:void(0);" @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=appDownload`, _backPage: 'top' } })" class="sticky-bottom-appdownload--button gtm-link_click_count01" data-gtm-event-cat-label="台風特集ページ2022リンク8iOS" data-gtm-event-act-label="trackingLpTyphoon2022Btn08_ios"><img src="~@/assets/img/sugotoku_app_info_btn03.png" alt="今すぐダウンロード！"></a>
		</aside>
		<!-- /下に吸着するアプリダウンロードボタン -->
	</section>
</template>

<script>
// Vuex
import { mapActions, mapGetters } from 'vuex'
import { SET_USER_DATA } from '@/store/modules/common/mutation-types'

// ライブラリ
import cmnConst from '@/assets/js/constant.js'
import Swiper from 'swiper'
import moment from 'moment'

export default {
	name: 'LpTyphoon2022',
	components: {
	},
	data () {
		return {
			sugotokuContentsId: cmnConst.SUGOTOKU_CONTENTS_ID,
			backendUrl: cmnConst.BACKEND_URL,
			accessDate: '',
			webStorage: {},
			isComplete: false,
			challengeDisplayType: [
				{ 'is_active': true, 'sprite_class': 'is-enabled' },
				{ 'is_active': false, 'sprite_class': 'is-disabled' },
				{ 'is_active': false, 'sprite_class': 'is-disabled' }
			]
		}
	},
	computed: {
		// map Vuex getters
		...mapGetters('common', ['isAndroid', 'userId', 'userToken', 'userData'])
	},
	mounted () {
		this.accessDate = moment().format('YYYYMMDD')

		if (!this.userData) {
			this.SET_USER_DATA({
				user_id: this.userId,
				user_token: this.userToken
			}).then(res => {
				console.log('userData', this.userData)
				this.setChallenge()
			}).catch(error => {
				console.log('Error SET_USER_DATA: ', error)
			})
		} else {
			this.setChallenge()
		}

		this.onSwiper()

		// ページ遷移後のアンカーリンク
		// NOTE: 今後統一する予定
		if (this.$route.hash) window.addEventListener('load', this.hashAnchorLink)
	},
	beforeDestroy () {
		window.removeEventListener('load', this.hashAnchorLink)
	},
	methods: {
		// map Vuex actions
		...mapActions('common', [SET_USER_DATA, 'updateWebStorage']),

		// チャレンジの表示
		setChallenge () {
			this.webStorage = this.userData.teigaku.web_storage && this.userData.teigaku.web_storage.data ? JSON.parse(this.userData.teigaku.web_storage.data) : {}

			if (this.accessDate === this.webStorage.c1) {
				// NOTE: c1チャレンジ完了日がアクセス日と同じ場合
				this.challengeDisplayType[0].is_active = false
				this.challengeDisplayType[0].sprite_class = 'is-complete'
			} else if (this.webStorage.hasOwnProperty('c1') && !this.webStorage.hasOwnProperty('c2') && (this.accessDate !== this.webStorage.c1)) {
				// NOTE: c1チャレンジ完了日がアクセス日と違う場合
				this.challengeDisplayType[0].is_active = false
				this.challengeDisplayType[0].sprite_class = 'is-complete'
				this.challengeDisplayType[1].is_active = true
				this.challengeDisplayType[1].sprite_class = 'is-enabled'
			} else if (this.accessDate === this.webStorage.c2) {
				// NOTE: c2チャレンジ完了日がアクセス日と同じ場合
				this.challengeDisplayType[0].is_active = false
				this.challengeDisplayType[0].sprite_class = 'is-complete'
				this.challengeDisplayType[1].is_active = false
				this.challengeDisplayType[1].sprite_class = 'is-complete'
			} else if (this.webStorage.hasOwnProperty('c2') && !this.webStorage.hasOwnProperty('c3') && (this.accessDate !== this.webStorage.c2)) {
				// NOTE: c2チャレンジ完了日がアクセス日と違う場合
				this.challengeDisplayType[0].is_active = false
				this.challengeDisplayType[0].sprite_class = 'is-complete'
				this.challengeDisplayType[1].is_active = false
				this.challengeDisplayType[1].sprite_class = 'is-complete'
				this.challengeDisplayType[2].is_active = true
				this.challengeDisplayType[2].sprite_class = 'is-enabled'
			} else if (this.accessDate === this.webStorage.c3) {
				// NOTE: 全チャレンジが完了した場合
				this.challengeDisplayType[0].is_active = false
				this.challengeDisplayType[0].sprite_class = 'is-complete'
				this.challengeDisplayType[1].is_active = false
				this.challengeDisplayType[1].sprite_class = 'is-complete'
				this.challengeDisplayType[2].is_active = false
				this.challengeDisplayType[2].sprite_class = 'is-complete'
				this.isComplete = true
			}
		},

		onClick (value, event) {
			event.preventDefault()

			switch (value) {
				case 1:
					this.webStorage.c1 = this.accessDate
					this.updateStorage()
					this.$router.push({ name: 'Cpsite', query: { url: `${this.backendUrl}?_path=selfcheckMeteoropathy` } })
					break
				case 2:
					this.webStorage.c2 = this.accessDate
					this.updateStorage()
					this.$router.push({ name: 'Cpsite', query: { url: `${this.backendUrl}?_path=columnDetail`, _detailId: 722 } })
					break
				case 3:
					this.webStorage.c3 = this.accessDate
					this.updateStorage()
					if (this.isAndroid) {
						location.href = 'dcmdam://launch?url=http%3A%2F%2Fapp-manager.docomo.ne.jp%2FSpApps%2FdetailApp%3Fdcmstore_view%3Dnone%26cId%3D10000023012'
					} else {
						this.$router.push({ name: 'Cpsite', query: { url: `${this.backendUrl}?_path=appDownload`, _backPage: 'top' } })
					}
					break
			}
		},

		// webStorageのアップデート
		updateStorage () {
			this.updateWebStorage({
				user_id: this.userId,
				user_token: this.userToken,
				data: this.webStorage
			}).then(res => {
				// NOTE: userDataの更新
				return this.SET_USER_DATA({
					user_id: this.userId,
					user_token: this.userToken
				})
			}).catch(error => {
				// NOTE: エラーの処理
				console.log(error)
			}).finally(() => {
				this.setChallenge()
			})
		},

		// ページスクロール用の関数
		onScroll (id) {
			const elem = document.getElementById(id)
			const options = {
				container: 'body',
				duration: 500,
				easing: 'ease-out',
				offset: 0,
				cancelable: false,
				onStart: false,
				onDone: false,
				onCancel: false,
				x: false,
				y: true
			}
			this.$scrollTo(elem, options)
		},

		// Swiperの処理
		onSwiper () {
			const swiperConstructor = () => {
				return new Swiper('.js_swiper', {
					slidesPerView: 1,
					spaceBetween: 10,
					observer: true,
					observeParents: true,
					navigation: {
						nextEl: '.js_swiper_next',
						prevEl: '.js_swiper_prev'
					},
					pagination: {
						el: '.js_swiper_pagination'
					}
				})
			}
			swiperConstructor()
		},

		// ページ遷移後のアンカーリンク
		// NOTE: 今後統一する予定
		hashAnchorLink () {
			const $elem = document.querySelector(this.$route.hash)
			if ($elem) $elem.scrollIntoView ? $elem.scrollIntoView({ behavior: 'smooth', block: 'start' }) : scrollTo(0, $elem.getBoundingClientRect().top)
		}
	}
}
</script>

<style lang="scss" scoped>
@import "../../assets/sass/variable";
$text-em: #FF4D5F;
$lp-background: #EBF4F6;
$lp-contents-primary-color: #009AD1;
$lp-contents-background: #DCE9F2;
$lp-contents-btn-background: #E271C0;
$lp-contents-btn-shadow: #D641A9;
$lp-contents-btn-off-background: #CCCCCC;
$lp-contents-btn-off-shadow: #B8B8B8;
$lp-contents-challenge-shadow: #FFC952;
$lp-stamp-line: #707070;
$spacing-12: 0.75rem;
$spacing-22: 1.375rem;
$spacing-28: 1.75rem;
$spacing-34: 2.125rem;
$spacing-86: 5.375rem;
$spacing-92: 5.75rem;
$spacing-120: 7.5rem;
$line-height-1375: 1.375;
$max-width-374: 0.85333;

.lp {
	margin: 0 auto;
	max-width: 640px;
	font-family: $lp-family-primary;
	font-size: $font-size-16;
	background-color: $lp-background;

	&__inner {
		background: url(~@/assets/img/lp_typhoon2022/rain.png) top center / 100% auto repeat-y;
	}

	&__header {
		margin: 0 0 $spacing-10;
		padding: $spacing-10 $spacing-14 0;

		img {
			margin: 0 auto;
			display: block;
			width: 100%;
		}
	}

	&__intro {
		&--text01 {
			margin: 0 auto $spacing-18;
			width: calc(610px / 2);
			height: calc(160px / 2);
			position: relative;

			@media (max-width: (374px)) {
				width: calc(610px / 2 * #{$max-width-374} );
				height: calc(160px / 2 * #{$max-width-374} );
			}

			p {
				margin: 0;
				position: absolute;
				left: 10px;
				top: 18px;
				font-size: $font-size-14;

				@media (max-width: (374px)) {
					top:15px;
					font-size: $font-size-12;
				}
			}
		}

		&--text02 {
			margin: 0 auto $spacing-4;
			width: calc(566px / 2);
			height: calc(191px / 2);
			position: relative;

			@media (max-width: (374px)) {
				width: calc(566px / 2 * #{$max-width-374} );
				height: calc(191px / 2 * #{$max-width-374} );
			}

			p {
				margin: 0;
				position: absolute;
				left: 120px;
				top: 10px;
				font-size: $font-size-14;

				em {
					color: $text-em;
					font-family: $lp-family-tertiary;
					font-style: normal;
					font-size: $font-size-18;
				}

				@media (max-width: (374px)) {
					left: 100px;
					top: 7px;
					font-size: $font-size-12;

					em {
						font-size: $font-size-16;
					}
				}
			}
		}

		&--text03 {
			margin: 0 auto $spacing-14;
			width: calc(528px / 2);
			height: calc(180px / 2);
			position: relative;

			@media (max-width: (374px)) {
				width: calc(528px / 2 * #{$max-width-374} );
				height: calc(180px / 2 * #{$max-width-374} );
			}

			p {
				margin: 0;
				position: absolute;
				left: 10px;
				top: 30px;
				font-size: $font-size-14;

				em {
					color: $text-em;
					font-family: $lp-family-tertiary;
					font-style: normal;
				}

				@media (max-width: (374px)) {
					top: 27px;
					font-size: $font-size-12;
				}
			}
		}
	}

	&__link {
		padding-top: (88 / 750 * 100%);
		width: 100%;
		background: url(~@/assets/img/lp_typhoon2022/contentstop_bg.png) top center / 100% auto no-repeat;

		&__inner {
			padding-bottom: $spacing-30;
			background: $lp-contents-background;
		}

		&--title {
			margin: -1px auto 0;
			width: calc(502px / 2);
			position: relative;
			text-align: center;

			@media (max-width: (374px)) {
				width: calc(502px / 2 * #{$max-width-374} );
			}

			span {
				margin: 0;
				width: calc(502px / 2);
				position: absolute;
				left: 0;
				top: -5px;
				font-weight: normal;
				font-family: $lp-family-secondary;

				@media (max-width: (374px)) {
					width: calc(502px / 2 * #{$max-width-374} );
					font-size: $font-size-14;
				}
			}
		}

		&--lead {
			margin: $spacing-14 0 $spacing-16;
			padding: 0 $spacing-14;

			em {
				color: $text-em;
				font-family: $lp-family-tertiary;
				font-style: normal;
			}
		}

		&--list {
			margin: 0 0 $spacing-16;
			padding: 0;

			li {
				padding: 0 $spacing-14;

				a {
					padding: $spacing-4;
					border: 2px solid $lp-contents-primary-color;
					border-radius: 200px;
					display: flex;
					align-items: center;
					justify-content: space-around;
					width: 100%;
					color: $lp-contents-primary-color;
					text-decoration: none;
					text-align: center;
					font-size: $font-size-18;
					background-color: $background-primary;
					box-sizing: border-box;

					@media (max-width: (374px)) {
						font-size: $font-size-16;
					}

					span {
						padding-right: $spacing-18;
						width: calc(100% - 38px);
						font-family: $lp-family-secondary;
					}

					small {
						width: calc(100% - 38px);
						font-size: $font-size-14;

						@media (max-width: (374px)) {
							font-size: $font-size-12;
						}
					}
				}

				&:nth-child(n + 2) {
					margin-top: $spacing-16;
				}
			}
		}

		&--text01 {
			margin: 0 auto;
			width: calc(668px / 2);
			height: calc(176px / 2);
			position: relative;

			@media (max-width: (374px)) {
				width: calc(668px / 2 * #{$max-width-374} );
				height: calc(176px / 2 * #{$max-width-374} );
			}

			p {
				margin: 0;
				position: absolute;
				left: 154px;
				top: 15px;
				width: 12.5em;
				font-size: $font-size-14;

				@media (max-width: (374px)) {
					left: 131px;
					top: 14px;
					font-size: $font-size-12;
				}
			}
		}
	}

	&-contents {
		overflow: hidden;
		background-color: $lp-contents-background;

		&__inner {
			margin: 0 auto $spacing-30;
			border: 2px solid $lp-contents-primary-color;
			border-radius: 10px;
			width: calc(100% - #{$spacing-26});
			background: $background-primary;
			overflow: hidden;
			box-sizing: border-box;
		}

		&__title {
			margin: 0;
			padding: $spacing-20 0 0;
			color: $text-seconday;
			min-height: 96px;
			font-family: $lp-family-tertiary;
			font-weight: normal;
			font-size: $font-size-24;
			text-align: center;
			background: url(~@/assets/img/lp_typhoon2022/contentstitle_bg.png) top center / 100% auto no-repeat;
			line-height: $line-height-1375;
			box-sizing: border-box;

			@media (max-width: (370px)) {
				min-height: 82px;
				font-size: $font-size-18;
			}

			span {
				display: block;
				font-family: $lp-family-tertiary;
				font-size: $font-size-18;

				@media (max-width: (370px)) {
					font-size: $font-size-14;
				}
			}
		}

		&__button {
			margin: 0 auto;
			padding: $spacing-10 0;
			border-radius: 6px;
			display: block;
			max-width: 295px;
			color: $text-seconday;
			font-family: $lp-family-secondary;
			font-style: normal;
			font-size: $font-size-18;
			text-align: center;
			text-decoration: none;
			background-color: $lp-contents-btn-background;
			box-shadow: 0 3px 0 0 $lp-contents-btn-shadow;

			@media (max-width: (374px)) {
				max-width: 240px;
				font-size: $font-size-16;
			}

			& + .lp-contents__usage--title {
				margin-top: $spacing-30;
			}
		}

		&__about {
			padding: 0 0 $spacing-22;

			&--img {
				margin: $spacing-20 auto $spacing-6;
				width: calc(568px / 2);

				@media (max-width: (374px)) {
					width: calc(568px / 2 * #{$max-width-374} );
				}
			}

			&--text {
				margin: 0 0 $spacing-12;
				text-align: center;
			}
		}

		&__usage {
			padding: 0 0 $spacing-18;

			&--title {
				margin: $spacing-18 auto 0;
				width: calc(620px / 2);
				font-family: $lp-family-tertiary;
				font-size: $font-size-18;
				text-align: center;

				&--top {
					margin: 0 auto $spacing-16;
				}

				&--bottom {
					margin: $spacing-12 auto 0;
				}

				@media (max-width: (374px)) {
					width: calc(620px / 2 * #{$max-width-374} );
					font-size: $font-size-16;
				}
			}

			&--text01 {
				margin: 0 auto $spacing-20;
				width: calc(614px / 2);
				height: calc(162px / 2);
				position: relative;

				@media (max-width: (374px)) {
					width: calc(614px / 2 * #{$max-width-374} );
					height: calc(162px / 2 * #{$max-width-374} );
				}

				p {
					margin: 0;
					position: absolute;
					left: 10px;
					top: 10px;
					width: 14.5em;
					font-size: $font-size-14;

					@media (max-width: (374px)) {
						font-size: $font-size-12;
					}
				}
			}

			&--text02 {
				margin: $spacing-20 auto $spacing-30;
				width: calc(614px / 2);
				height: calc(164px / 2);
				position: relative;

				@media (max-width: (374px)) {
					width: calc(614px / 2 * #{$max-width-374} );
					height: calc(164px / 2 * #{$max-width-374} );
				}

				p {
					margin: 0;
					position: absolute;
					left: 99px;
					top: 10px;
					width: 14.5em;
					font-size: $font-size-14;

					@media (max-width: (374px)) {
						left: 84px;
						font-size: $font-size-12;
					}
				}
			}

			&--healthweather--img {
				margin: $spacing-16 auto $spacing-12;
				display: block;
				width: calc(556px / 2);
				height: calc(456px / 2);

				@media (max-width: (374px)) {
					width: calc(556px / 2 * #{$max-width-374} );
					height: calc(456px / 2 * #{$max-width-374} );
				}
			}

			&--healthweather--text {
				margin: 0 0 $spacing-14;
				padding: 0 $spacing-20;
			}
		}

		&__challenge {
			&--lead {
				margin: $spacing-12 0 $spacing-18;
				padding: 0 $spacing-20;
			}

			&--text01 {
				margin: 0 auto $spacing-16;
				width: calc(614px / 2);
				height: calc(164px / 2);
				position: relative;

				@media (max-width: (374px)) {
					width: calc(614px / 2 * #{$max-width-374} );
					height: calc(164px / 2 * #{$max-width-374} );
				}

				p {
					margin: 0;
					position: absolute;
					left: 10px;
					top: 9px;
					width: 11.5em;
					font-size: $font-size-14;

					@media (max-width: (374px)) {
						font-size: $font-size-12;
					}
				}
			}

			&--stamptitle {
				margin: 0 0 $spacing-6;
				color: $text-em;
				font-family: $lp-family-secondary;
				text-align: center;
			}

			&--stamp {
				margin: 0 auto $spacing-16;
				padding: 0;
				display: flex;
				align-items: center;
				justify-content: space-between;
				max-width: 312px;
				width: auto;

				@media (max-width: (374px)) {
					max-width: 260px;
				}

				li {
					border: 1px solid $lp-stamp-line;
					border-radius: 10px;
					display: flex;
					align-items: center;
					justify-content: center;
					width: 68px;
					height: 68px;
					background-color: $background-primary;
					overflow: hidden;
					box-sizing: border-box;

					@media (max-width: (374px)) {
						width: 58px;
						height: 58px;
					}

					img {
						display: block;
						width: 100%;
						pointer-events: none;
					}
				}
			}

			&--box {
				padding-top: (88 / 750 * 100%);
				width: 100%;
				background: url(~@/assets/img/lp_typhoon2022/challenge_top.png) top center / 100% auto no-repeat;
			}

			&--explanation {
				margin: 0;
				padding: $spacing-10 $spacing-20 $spacing-8;
				background-color: $lp-background;
			}

			&--term {
				padding: 0 0 $spacing-18;
				color: $text-em;
				font-family: $lp-family-secondary;
				text-align: center;
				background-color: $lp-background;
			}

			&--inner {
				padding: $spacing-20 0;
				width: 100%;
				background: url(~@/assets/img/lp_typhoon2022/challenge_title.png) 20px top / calc(100% - 40px) auto no-repeat, url(~@/assets/img/lp_typhoon2022/rain.png) center top / 100% auto repeat-y $lp-background;
				box-sizing: border-box;
			}

			&--start {
				margin: 0 auto $spacing-34;
				width: calc(510px / 2);
				height: calc(126px / 2);

				@media (max-width: (374px)) {
					width: calc(510px / 2 * #{$max-width-374} );
					height: calc(126px / 2 * #{$max-width-374} );
				}
			}

			&--contents {
				margin: 0 auto;
				padding: 0;
				width: 307px;

				@media (max-width: (374px)) {
					width: 250px;
				}

				li {
					margin: 0;
					padding: 0;
					display: block;
					width: 220px;
					position: relative;

					&:nth-child(1) {
						margin: 0 auto;
						padding-bottom: $spacing-120;
						background: url(~@/assets/img/lp_typhoon2022/challenge_arrow01.png) 98px bottom / calc(156px / 2) auto no-repeat;

						@media (max-width: (374px)) {
							padding-bottom: $spacing-92;
							background-position: 88px bottom;
						}

						a {
							position: absolute;
							left: calc(50% - 100px);
							top: 190px;

							@media (max-width: (374px)) {
								left: calc(50% - 87px);
								top: 165px;
							}
						}
					}

					&:nth-child(2) {
						margin: 0 0 0 auto;
						padding-bottom: $spacing-120;
						background: url(~@/assets/img/lp_typhoon2022/challenge_arrow02.png) -4px bottom / calc(280px / 2) auto no-repeat;

						@media (max-width: (374px)) {
							margin: 0 -#{$spacing-20} 0 auto;
							padding-bottom: $spacing-92;
							background-position: 6px bottom;
						}

						a {
							position: absolute;
							left: calc(50% - 100px);
							top: 190px;

							@media (max-width: (374px)) {
								left: calc(50% - 87px);
								top: 165px;
							}
						}
					}

					&:nth-child(3) {
						margin: 0;

						@media (max-width: (374px)) {
							margin: 0 0 0 -#{$spacing-20};
						}

						a {
							margin: $spacing-20 auto;

							@media (max-width: (374px)) {
								margin: -#{$spacing-10} auto $spacing-20;
							}
						}
					}
				}
			}

			&--date {
				position: absolute;
				left: 50%;
				top: -24px;
				z-index: 2;
				transform: translateX(-50%);

				@media (max-width: (374px)) {
					top: -22px;
				}
			}

			&--img {
				margin: 0 auto;
				padding: $spacing-10;
				display: block;
				border-radius: 10px;
				background-color: $background-primary;
				box-sizing: border-box;

				@media (max-width: (374px)) {
					transform: scale(0.85333);
					transform-origin: center 0;
				}

				&.active {
					animation: flash 2s ease infinite;
				}

				i {
					transform: scale(1);
				}
			}

			&--link {
				padding: $spacing-10 0;
				border-radius: 6px;
				display: block;
				width: 200px;
				color: $text-seconday;
				text-align: center;
				text-decoration: none;
				background-color: $lp-contents-btn-background;
				font-size: $font-size-18;
				font-family: $lp-family-secondary;

				@media (max-width: (374px)) {
					width: 174px;
					font-size: $font-size-14;
				}
			}

			&--btn {
				margin: $spacing-30 auto 0;
				padding: $spacing-10 0;
				border-radius: 6px;
				display: block;
				width: 296px;
				color: $text-seconday;
				text-align: center;
				text-decoration: none;
				background-color: $lp-contents-btn-off-background;
				font-size: $font-size-18;
				font-family: $lp-family-secondary;
				box-shadow: 0 3px 0 0 $lp-contents-btn-off-shadow;
				pointer-events: none;

				@media (max-width: (374px)) {
					margin: 0 auto;
					width: 240px;
					font-size: $font-size-14;
				}

				&.active {
					background-color: $lp-contents-btn-background;
					box-shadow: 0 3px 0 0 $lp-contents-btn-shadow;
					pointer-events: auto;
				}
			}

			&--arrow01  {
				margin: $spacing-8 0 $spacing-12 $spacing-86;
			}

			&--hiroshi {
				width: calc(144px / 2);
				height: calc(190px / 2);
				position: absolute;
				top: 74px;
				left: -88px;

				@media (max-width: (374px)) {
					width: calc(144px / 2 * #{$max-width-374} );
					height: calc(190px / 2 * #{$max-width-374} );
					top: 52px;
					left: -62px;
				}
			}

			&--arrow02  {
				margin: $spacing-8 0 $spacing-12 -#{$spacing-12};
			}

			&--maro {
				width: calc(150px / 2);
				height: calc(160px / 2);
				position: absolute;
				top: 90px;
				right: -88px;

				@media (max-width: (374px)) {
					width: calc(150px / 2 * #{$max-width-374} );
					height: calc(160px / 2 * #{$max-width-374} );
					top: 70px;
					right: -54px;
				}
			}
		}
	}

	&__outro {
		margin-top: $spacing-20;

		p {
			margin: 0 0 $spacing-14;
			text-align: center;
			font-family: $lp-family-secondary;
			font-weight: normal;
		}

		&--img {
			margin: 0 auto $spacing-20;
			width: calc(501px / 2);
			height: calc(380px / 2);

			@media (max-width: (374px)) {
				width: calc(501px / 2 * #{$max-width-374} );
				height: calc(380px / 2 * #{$max-width-374} );
			}
		}

		&--button {
			margin: $spacing-34 0 0 0;

			&-pagetop {
				margin: 0 auto $spacing-16;
				padding: $spacing-6 0;
				border: 3px solid $primary;
				border-radius: 100px;
				position: relative;
				display: block;
				max-width: 295px;
				color: $primary;
				font-family: $lp-family-secondary;
				font-weight: normal;
				font-size: $font-size-18;
				background-color: $background-primary;
				text-align: center;
				text-decoration: none;
			}

			&-back {
				margin: 0 auto;
				padding: $spacing-8 0;
				border-radius: 100px;
				position: relative;
				display: block;
				max-width: 295px;
				color: $text-seconday;
				font-family: $lp-family-secondary;
				font-weight: normal;
				font-size: $font-size-24;
				background-color: $primary;
				text-align: center;
				text-decoration: none;
			}
		}
	}
}

.sticky-bottom-appdownload {
	margin-top: $spacing-20;
	padding: $spacing-8 $spacing-14;
	box-shadow: 0px -2px 2px rgba(0,0,0,0.1);
}

// animation
@keyframes flash {
  0% {
	background-color: $background-primary;
  }

  40% {
	background-color: $lp-contents-challenge-shadow;
  }

  70% {
	background-color: $background-primary;
  }
}

// swiper
.swiper-wrapper {
	margin: 0;
	padding: 0;
	list-style-type: none;

	li {
		padding: $spacing-16 0 0;
		border-radius: 10px;
		border: 4px solid $background-list;
		width: 308px;
		height: 308px;
		box-sizing: border-box;

		@media (max-width: (374px)) {
			padding: $spacing-10 0 0;
			width: 260px;
			height: 260px;
		}

		p {
			margin: 0 auto;
			width: 17.5em;
			text-align: center;

			@media (max-width: (374px)) {
				font-size: $font-size-14;
			}
		}

		i {
			margin: 0 auto;

			@media (max-width: (374px)) {
				transform-origin: center 0;
			}
		}

		&:nth-child(1) p {
			margin-bottom: $spacing-2;
		}

		&:nth-child(2) p {
			margin-bottom: $spacing-2;
		}

		&:nth-child(3) p {
			margin-bottom: $spacing-28;
		}

		&:nth-child(4) p {
			margin-bottom: $spacing-12;
		}

		&:nth-child(5) p {
			margin-bottom: $spacing-22;
		}
	}
}

.usage__slider {
	margin: $spacing-12 auto $spacing-10;
	position: relative;
	width: 308px;

	@media (max-width: (374px)) {
		width: 260px;
	}
}

.usage__slider--nav {
	.swiper-button-prev,
	.swiper-button-next {
		margin-top: -#{$spacing-34};
		width: 34px;
		height: 34px;

		@media (max-width: (374px)) {
			width: 28px;
			height: 28px;
		}

		&::before,
		&::after {
			display: none;
		}

		&.swiper-button-disabled {
			opacity: 0;
		}
	}
	.swiper-button-prev {
		left: 8px;
		background: url(~@/assets/img/lp_typhoon2022/slider_prev.png) center center / 100% auto no-repeat;
	}
	.swiper-button-next {
		right: 8px;
		background: url(~@/assets/img/lp_typhoon2022/slider_next.png) center center / 100% auto no-repeat;
	}
}

.swiper-pagination-bullets {
	padding: $spacing-14 0;
	display: flex;
	align-items: center;
	justify-content: center;
	/deep/ .swiper-pagination-bullet {
		margin: 0 $spacing-10;
		width: 5px;
		height: 5px;
		background: $button-secondary;
		opacity: 1;
	}
	/deep/ .swiper-pagination-bullet-active {
		background: $text-primary;
	}
}

// sprite
.sprite_typhoon {
	background-image: url(~@/assets/img/lp_typhoon2022/sprite_typhoon.png);
	background-repeat: no-repeat;
	display: block;
	background-size: (678px / 2) (4248px / 2);

	@media (max-width: (374px)) {
		transform: scale(.85333);
		transform-origin: center;
	}

	&.about_img {
		width: calc(568px / 2);
		height: calc(312px / 2);
		background-position: (-5px / 2) (-5px / 2);

		@media (max-width: (374px)) {
			transform-origin: 0 0;
		}
	}

	&.balloon_01 {
		width: calc(610px / 2);
		height: calc(160px / 2);
		background-position: (-5px / 2) (-327px / 2);

		@media (max-width: (374px)) {
			transform-origin: 0 0;
		}
	}

	&.balloon_02 {
		width: calc(566px / 2);
		height: calc(191px / 2);
		background-position: (-5px / 2) (-497px / 2);

		@media (max-width: (374px)) {
			transform-origin: 0 0;
		}
	}

	&.balloon_03 {
		width: calc(528px / 2);
		height: calc(180px / 2);
		background-position: (-5px / 2) (-698px / 2);

		@media (max-width: (374px)) {
			transform-origin: 0 0;
		}
	}

	&.balloon_04 {
		width: calc(668px / 2);
		height: calc(176px / 2);
		background-position: (-5px / 2) (-888px / 2);

		@media (max-width: (374px)) {
			transform-origin: 0 0;
		}
	}

	&.balloon_05 {
		width: calc(614px / 2);
		height: calc(162px / 2);
		background-position: (-5px / 2) (-1074px / 2);

		@media (max-width: (374px)) {
			transform-origin: 0 0;
		}
	}

	&.balloon_06 {
		width: calc(614px / 2);
		height: calc(164px / 2);
		background-position: (-5px / 2) (-1246px / 2);

		@media (max-width: (374px)) {
			transform-origin: 0 0;
		}
	}

	&.balloon_07 {
		width: calc(594px / 2);
		height: calc(162px / 2);
		background-position: (-5px / 2) (-1420px / 2);

		@media (max-width: (374px)) {
			transform-origin: 0 0;
		}
	}

	&.carousel_01 {
		width: calc(480px / 2);
		height: calc(388px / 2);
		background-position: (-5px / 2) (-1592px / 2);
	}

	&.carousel_02 {
		width: calc(480px / 2);
		height: calc(388px / 2);
		background-position: (-5px / 2) (-1990px / 2);
	}

	&.carousel_03 {
		width: calc(480px / 2);
		height: calc(240px / 2);
		background-position: (-5px / 2) (-2388px / 2);
	}

	&.carousel_04 {
		width: calc(480px / 2);
		height: calc(270px / 2);
		background-position: (-5px / 2) (-2638px / 2);
	}

	&.carousel_05 {
		width: calc(480px / 2);
		height: calc(256px / 2);
		background-position: (-5px / 2) (-2918px / 2);
	}

	&.contentssubtitle_bottom {
		width: calc(619px / 2);
		height: calc(25px / 2);
		background-position: (-5px / 2) (-3184px / 2);

		@media (max-width: (374px)) {
			transform-origin: 0 0;
		}
	}

	&.contentssubtitle_top {
		width: calc(621px / 2);
		height: calc(4px / 2);
		background-position: (-5px / 2) (-3219px / 2);

		@media (max-width: (374px)) {
			transform-origin: 0 0;
		}
	}

	&.healthweather {
		width: calc(556px / 2);
		height: calc(456px / 2);
		background-position: (-5px / 2) (-3233px / 2);
	}

	&.linktitle_bg {
		width: calc(502px / 2);
		height: calc(80px / 2);
		background-position: (-5px / 2) (-3699px / 2);

		@media (max-width: (374px)) {
			transform-origin: 0 0;
		}
	}

	&.outro_img {
		width: calc(501px / 2);
		height: calc(380px / 2);
		background-position: (-5px / 2) (-3789px / 2);

		@media (max-width: (374px)) {
			transform-origin: 0 0;
		}
	}

	&.typhoon_icon {
		width: calc(64px / 2);
		height: calc(64px / 2);
		background-position: (-5px / 2) (-4179px / 2);
	}
}
.sprite_challenge {
	background-image: url(~@/assets/img/lp_typhoon2022/sprite_challenge.png);
	background-repeat: no-repeat;
	display: block;
	background-size: (520px / 2) (3476px / 2);

	@media (max-width: (374px)) {
		transform: scale(.85333);
		transform-origin: center;
	}

	&.challenge01 {
		&.is-enabled {
			width: calc(400px / 2);
			height: calc(320px / 2);
			background-position: (-5px /2) (-5px / 2);
		}

		&.is-complete {
			width: calc(400px / 2);
			height: calc(320px / 2);
			background-position: (-5px /2) (-335px / 2);
		}
	}

	&.challenge02 {
		&.is-disabled {
			width: calc(400px / 2);
			height: calc(320px / 2);
			background-position: (-5px /2) (-1325px / 2);
		}

		&.is-enabled {
			width: calc(400px / 2);
			height: calc(320px / 2);
			background-position: (-5px /2) (-665px / 2);
		}

		&.is-complete {
			width: calc(400px / 2);
			height: calc(320px / 2);
			background-position: (-5px /2) (-995px / 2);
		}
	}

	&.challenge03 {
		&.is-disabled {
			width: calc(400px / 2);
			height: calc(320px / 2);
			background-position: (-5px /2) (-2315px / 2);
		}

		&.is-enabled {
			width: calc(400px / 2);
			height: calc(320px / 2);
			background-position: (-5px /2) (-1655px / 2);
		}

		&.is-complete {
			width: calc(400px / 2);
			height: calc(320px / 2);
			background-position: (-5px /2) (-1985px / 2);
		}
	}

	&.challenge_hiroshi {
		width: calc(144px / 2);
		height: calc(190px / 2);
		background-position: (-5px /2) (-2645px / 2);
	}

	&.challenge_maro {
		width: calc(150px / 2);
		height: calc(160px / 2);
		background-position: (-5px /2) (-2845px / 2);
	}

	&.challenge_ribbon_01 {
		width: calc(274px / 2);
		height: calc(100px / 2);
		background-position: (-5px /2) (-3015px / 2);
	}

	&.challenge_ribbon_02 {
		width: calc(274px / 2);
		height: calc(100px / 2);
		background-position: (-5px /2) (-3125px / 2);
	}

	&.challenge_ribbon_03 {
		width: calc(274px / 2);
		height: calc(100px / 2);
		background-position: (-5px /2) (-3235px / 2);
	}

	&.challenge_start {
		width: calc(510px / 2);
		height: calc(126px / 2);
		background-position: (-5px /2) (-3345px / 2);

		@media (max-width: (374px)) {
			transform-origin: 0 0;
		}
	}
}

</style>
